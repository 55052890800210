/*----------------------------------------------------------------------------*\
    Main
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

/*
CONTENTS................You're reading it, LOL
MODULE..................Includes base module & submodules
STATE...................States the module can be in
THEME...................Theming of the module
*/





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

.nav{

}
.nav-top{

	li{
		a{
			text-decoration: none;
			padding: $space-half;
			color: color(base-dark);
			font-size: $milli;
		}
	}

border-bottom: 1px solid #fff;
}
.nav-bot{
	li{
		a{
			text-decoration: none;
			padding: $space;
			color: color(base-dark);
			font-size: $gamma;
		}
	}
}
.nav-mob__top, .nav-mob__bot{

	li{
		list-style-type: none;
		margin: $space;
		a{
			text-decoration: none;
			color: color(base);
			font-weight: 700;
		}
	}
}

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

/* Media Queries
   The default media queries shipped with Blocss:
   Uncomment the ones you need
\*------------------------------------------------------------------------*/

/**
 * Desk-wide state
 */
//@include media-query(gt-desk){}


/**
 * Desk state
 */
//@include media-query(desk){}


/**
 * Portable state
 */
//@include media-query(lt-desk){}


/**
 * Lap state
 */
//@include media-query(lap){}


/**
 * Lapup state
 */
//@include media-query(gt-palm){}


/**
 * Palm state
 */
//@include media-query(palm){}





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/