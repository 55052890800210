/*----------------------------------------------------------------------------*\
    $TOPLEVEL
    html, body, .wrapper
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    /* commented backslash hack \*/
    html, body { height: 100%; }
    /* end hack */
    * html .wrapper { height: 100%; }

    body{
        position: relative;
        min-height: 100%;
        -webkit-font-smoothing: antialiased;
    }


    /**
     * Make wrapper 100% height
     */
    .wrapper {
        position: relative;
        min-height: 100%;
        height: auto;
        width: 100%;
        margin: 0 auto;
    }

        .site-width{
             margin: 0 auto;
             @include rem(max-width, $site-width);
             @include rem(padding, 0 $space);
         }

         .site-width--nopadding {
           padding: 0;
         }

         @include media-query(palm) {
            .site-width--nopadding--palm {
              padding: 0;
            }
          }
