/*----------------------------------------------------------------------------*\
    $VARS
    Any variables you find set in blocss’ `_defaults.scss` that you do not wish
    to keep, simply redefine here. This means that if blocss, for example,
    sets your `$base-font-size` at 16px and you wish it to be 14px, simply
    redeclare that variable in this file. Blocss ignores its own variables
    in favour of using your own, so you can completely modify how blocss
    works without ever having to alter the framework itself.
\*----------------------------------------------------------------------------*/

    /* Modules */
    $use-arrange:                       true;
    $use-buttons:                       true;
    $use-flexembed:                     false;
    $use-island:                        true;
    $use-media:                         false;
    $use-navigation:                    true;

    $use-grid:                          true;
        $grid-gutter-multiplier:        1;
        $enable-grid-rev:               false;
        $enable-grid-right:             false;
        $enable-grid-center:            true;
        $enable-grid-middle:            true;
        $enable-grid-narrow:            false;
        $enable-grid-wide:              true;
        $enable-grid-flush:             true;

    /* Generic */
    $use-units:                         true;
    $use-push:                          true;
    $use-pull:                          false;

    $divisions:                         12;

    /* Responsive */
    $breakpoints: (
        'palm'      '(max-width: 600px)',
        'lap'       '(min-width: 601px) and (max-width: 960px)',
        'desk'      '(min-width: 961px)'
    );

    $breakpoint-has-units:              ('palm', 'lap', 'desk');
    $breakpoint-has-push:               ('palm', 'lap', 'desk');
    $breakpoint-has-collapsed-arrange:  ('palm', 'lap');
    $breakpoint-has-pull:               ();
    $breakpoint-has-visuallyhidden:     ('palm', 'lap');

/*----------------------------------------------------------------------------*\
    $OVERRIDES
    Place any variables that should override blocss’ defaults here.
\*----------------------------------------------------------------------------*/

    $base-font-size:                        14px;
    $base-line-height:                      24px;
    $line-height-ratio:                     $base-line-height/$base-font-size;

    $giga:                                  96px;
    $mega:                                  40px;
    $kilo:                                  34px;

    $alpha:                                 24px;
    $beta:                                  22px;
    $gamma:                                 20px;
    $delta:                                 18px;
    $epsilon:                               16px;
    $zeta:                                  14px;

    $milli:                                 12px;
    $micro:                                 10px;

    $face-base:                             Helvetica, Arial, sans-serif;
    // $face-brand:                            'Fredoka One', Helvetica, Arial, sans-serif;

/*----------------------------------------------------------------------------*\
    $CUSTOM
    Place any of your own variables that sit on top of blocss here.
\*----------------------------------------------------------------------------*/

    $colors: (
            base:                         #F2F0F1,
            base-dark:                    #636363,
            subtle:                       #F4EFE7,
            blank:                        #ffffff,
            brand:                        #0093d6,
            brand-sec:                    #E1B742,
            brand-tri:                    #3aaa35,

    );

    $z-indexes: (
        main: (
                above-inherit: (),
                nested: (
                        low,
                        middle,
                        high
                ),
                tooltip: ()
        ),
        header: (),
        toaster: ()
    );

    $site-width:                        1200px;

    $transition:                        cubic-bezier(.55,0,.1,1);
