/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

@include media-query(palm){
    .card__map {
        display: none;
    }
}

/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/

.card {
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.4);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: $space;
}

.card__header {
    img {
        max-height: 115px;
        display: block;
        margin: 0 auto;
        padding-top: $space/4;
    }
}

.card__header--border--brand {
    border-bottom: solid 3px color(brand);
}

.card__header--border--brand-sec {
    border-bottom: solid 3px color(brand-sec);
}

.card__content {
    padding: $space $space $space/4;
}

.card__map {
    border-radius: 10px;
    overflow: hidden;
}

.card__button {
    padding: 10px 30px 10px 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $delta;
    display: inline-block;
    text-decoration: none;
    color: #000;
}

.card__button--yellow {
    background: url(../gfx/icons/right-yellow.svg);
    background-position: right center;
    background-size: 12px;;
    background-repeat: no-repeat;
}

.card__button--blue {
    background: url(../gfx/icons/right-blue.svg);
    background-position: right center;
    background-size: 12px;;
    background-repeat: no-repeat;
}
