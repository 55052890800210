/*----------------------------------------------------------------------------*\
    $Helpers
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */



/*----------------------------------------------------------------------------*\
    $FUNCTIONS
\*----------------------------------------------------------------------------*/

@function z-index($keys) {
    $map: $z-indexes;
    $found-index: null;

    @each $key in $keys {
        @if (type-of($map) == "map") {
            $found-index: index(map-keys($map), $key);
            $map: map-get($map, $key);
        }
        @else {
            $found-index: index($map, $key);
        }
    }

    @return $found-index;
}

@function z($keys...) {
    @return z-index($keys);
}