/*----------------------------------------------------------------------------*\
    $COLOR
\*----------------------------------------------------------------------------*/

/* Color function
   Using colors in css example:
   background-color: color(brand);
\*------------------------------------------------------------------------*/
@function color($key) {
    @if map-has-key($colors, $key) {
        @return map-get($colors, $key);
    }

    @error "Unknown `#{$key}` in $colors.";
    @return null;
}

/**
 * Backgrounds & colors
 */
        @each $color in $colors {

            $label: nth($color, 1);
            $value: nth($color, 2);

            .bg--#{$label} {
                background-color: $value;
            }

            .color--#{$label} {
                color: $value !important;
            }

            .blocklink--#{$label} {
                background-color: $value !important;
            }
            .hero--#{$label}{
                background-color: $value !important;

                .hero__content:after{
                    background-color: $value !important;
                }
            }
            .button--#{$label}{
                background-color: $value !important;

                &:hover{
                    background-color: lighten($value, 1) !important;
                }
            }
            .button--inv.button--#{$label}{
                color: $value !important;
                box-shadow: inset 0 0 0 1px $value;
            }
        }
