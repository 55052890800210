/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

@include media-query(palm){
    .grid {
        display: flex;
        flex-direction: column-reverse;
    }
}

/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/

.content-links {
    margin-bottom: $space;

    ul {
        margin: 0;
        list-style: none;

        a {
            text-decoration: none;
        }
    }
}
